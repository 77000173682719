import { Segment } from '@capturi/filters'
import { Tab } from '@capturi/ui-components'
import {
  Box,
  BoxProps,
  Progress,
  ProgressProps,
  Skeleton,
  TabProps,
  Text,
  Tooltip,
  useTab,
} from '@chakra-ui/react'
import React from 'react'
import { MdInfo } from 'react-icons/md'

type Props = {
  label: React.ReactNode
  values?: Segment<number | string>[]
  formatNumber?: (value: number) => string
  unit?: string
  isLoading?: boolean
  onClick?: () => void
  infoText?: string
  speaker?: string
  isScrolling?: boolean
  showProgress?: boolean
  progressMin?: number
  progressMax?: number
}

const TabContext = React.createContext(false)

export const Container = (props: TabProps): React.ReactElement => {
  const tabProps = useTab(props)
  const isSelected = !!tabProps['aria-selected']

  return (
    <TabContext.Provider value={isSelected}>
      <Tab
        pos="relative"
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        minW={0}
        minH={16}
        textAlign="left"
        flexShrink={0}
        {...tabProps}
      />
    </TabContext.Provider>
  )
}

export const Title = ({
  label,
  isScrolling,
}: {
  label: React.ReactNode
  isScrolling?: boolean
}): React.ReactElement => {
  return (
    <Tooltip label={label} openDelay={300} isDisabled={isScrolling}>
      <Box
        fontSize="sm"
        pr={6}
        lineHeight={1.8}
        width="100%"
        noOfLines={1}
        wordBreak="break-all"
      >
        <Box as="span">{label}</Box>
      </Box>
    </Tooltip>
  )
}

export const Status = ({
  statusLabel,
}: {
  statusLabel: string
}): React.ReactElement => {
  return (
    <Tooltip hasArrow aria-label={statusLabel} label={statusLabel}>
      <Box pos="absolute" right="8px" top="9px">
        <Box as={MdInfo} color="warning" />
      </Box>
    </Tooltip>
  )
}

export const CustomProgress = ({
  min,
  max,
  value,
  isLoading,
  ...props
}: ProgressProps & {
  isLoading: boolean
}): React.ReactElement => {
  const isSelected = React.useContext(TabContext)

  return (
    <Skeleton
      height={isSelected ? '4px' : '2px'}
      isLoaded={!isLoading}
      color="white"
      fadeDuration={1}
    >
      <Progress
        isAnimated
        value={value}
        min={min}
        max={max}
        transition="all 200ms ease-out"
        mt="2px"
        h="2px"
        transform={isSelected ? 'scale(1, 2)' : undefined}
        bg="gray.300"
        colorScheme={'segments.primary'}
        {...props}
      />
    </Skeleton>
  )
}

export const Speaker = ({
  speaker,
}: {
  speaker: string
}): React.ReactElement => (
  <Text fontSize="xs" color="textMuted">
    {speaker}
  </Text>
)

type ValueProps = Pick<Props, 'unit' | 'speaker' | 'formatNumber'> & {
  children: React.ReactNode
  isLoading: boolean
} & BoxProps

export const Value: React.FC<ValueProps> = ({
  children,
  isLoading = false,
  ...props
}) => {
  return (
    <Skeleton
      isLoaded={!isLoading}
      width={isLoading ? '50px' : undefined}
      height={isLoading ? '1rem' : undefined}
      fadeDuration={0.2}
    >
      <Box fontWeight="500" fontSize="md" {...props}>
        {children}
      </Box>
    </Skeleton>
  )
}

type DisplayProps = Pick<Props, 'unit' | 'formatNumber'> & {
  label?: string
  children: (isSelected: boolean) => React.ReactElement
}

export const Display: React.FC<DisplayProps> = ({
  children,
  ...props
}): React.ReactElement => {
  const isSelected = React.useContext(TabContext)

  return (
    <Box w="full" pos="relative">
      <Box fontSize="1rem" lineHeight={1.5} {...props}>
        {children(isSelected)}
      </Box>
    </Box>
  )
}

export default {
  Display,
  Container,
  Title,
  Status,
  CustomProgress,
  Speaker,
  Value,
}
